<template>
  
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo hidden">
          <h2 class="brand-text text-primary ml-1">
            Phire Studio
          </h2>
        </b-link>
        <!-- /Brand logo-->
  
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->
  
        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <img :src="daikinLogo" style="width: 100%; height: auto; margin: 0 0 15px 0"/>
  
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              QR Delivery Order Login
            </b-card-title>
  
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- Scan SAP DO -->
                <b-form-group
                  label="Scan Delivery Order"
                  label-for="login-qr"
                >
                  <b-form-input
                    disabled=true
                    id="login-qr"
                    v-model="deliveryOrder"
                    name="login-qr"
                    placeholder="SAP DO"
                  />
                </b-form-group>

                <!-- Insert Pin -->
                <b-form-group
                  label="Insert Pin"
                  label-for="login-pin-qr"
                >
                  <b-form-input
                    :disabled="pinDisabled"
                    id="login-pin-qr"
                    v-model="pin"
                    name="login-pin-qr"
                    placeholder="PIN"
                  />
                </b-form-group>

                <b-button
                  type="submit"
                  :disabled="pinDisabled"
                  variant="success"
                  block
                  @click="confirmPIN"
                >
                  Access DO
                </b-button>

                </b-form>
            </validation-observer>
  

  
            <!-- divider -->
            <div class="divider my-2">

            </div>
  
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import { mapState } from 'vuex';
  // import { viewAccessLogin } from '@/utils/utils'
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        currentDo:[],
        status: '',
        pin:'',
        pinDisabled:true,
        deliveryOrder: '',
        pinModal: false,
        modalScan: false,
        barcode: null,
        startScan: false,
        barcodeImage: require("@/assets/images/drcc/barcode.png"),
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        // validation rulesimport store from '@/store/index'
        required,
        email,
        daikinLogo: require('@/assets/images/drcc/daikin-logo.png')
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
      scan() {
        const foundDelivery = this.currentDo.find(
          delivery => delivery.sapDo === this.deliveryOrder
        );
        if (foundDelivery) {
          if (foundDelivery.status == "Fulfilled"){
            this.$bvToast.toast("This DO is already delivered. DO status is fulfilled.", {
              title: "Finished",
              variant: "success",
              solid: true,
            });
            return;
          }
          if (foundDelivery.status !== "Delivery in Progress") {
            //this.modalScan =false;
            this.$bvToast.toast("This DO is not ready for delivery. Current Status "+foundDelivery.status, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
            return;
          }

          this.pinDisabled=false;
        } else {
          
          this.$bvToast.toast("Delivery Order is Not Exists", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      },
      clearBarcode() {
        this.deliveryOrder = null;
      },

      maxlength(e){
        return String(e).substring(0,4);
      },
      confirmPIN() {
        // Validate the entered PIN here, for example:
        if (this.pin.length !== 4) {
          this.$bvToast.toast("Please enter a 4 digit long pin", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          return;
        }
        const foundDelivery = this.currentDo.find(
          delivery => delivery.sapDo === this.deliveryOrder
        );
        if (this.pin == foundDelivery.pin) {
          // If the PIN is valid, navigate to the detail delivery page
          this.$router.push({
            name: 'warehouse-detail-delivery-qr',
            params: { id: foundDelivery._id, from: "qr",pin:this.pin },
            //query: { pin: this.pin }
          });

        } else {
          this.$bvToast.toast("Incorrect PIN", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          return;
        }
      
        // Close the modal
        //this.pinModal = false;
      }
    },
    mounted() {
      this.deliveryOrder= this.$route.params.doNumber;


      
      this.$store.dispatch("delivery/getDeliveryOrderByDoNumber",this.deliveryOrder).then((x)=>{
        this.currentDo=x;
        this.scan()})
      }
  }
  </script>
  
  <style lang="scss">
  </style>
  